/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, hasCaptcha, hasGoogleTags }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const captchaScripts = [
    {
      src: "https://www.google.com/recaptcha/api.js",
      type: "text/javascript",
    },
  ];
  const googleTagScripts = [
    {
      innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-966661080');
      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') { window.location = url; }
        };
        gtag('event', 'conversion', { 'send_to': 'AW-966661080/xk_9CNqc4NkBENin-MwD', 'event_callback': callback });
        return false;
      }`,
      type: "text/javascript",
    },
  ];

  let extraScripts = hasCaptcha ? captchaScripts : undefined;
  extraScripts = hasGoogleTags
    ? extraScripts.concat(googleTagScripts)
    : extraScripts;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={extraScripts}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'j4LGwFWJkDtpZ9gxUthlTXrjUSbz9rjjvL7TbnCZasI'
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  hasCaptcha: PropTypes.bool,
  hasGoogleTags: PropTypes.bool,
};

export default SEO;

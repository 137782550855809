import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Button = ({ external, label, submit, link }) => {
  const classes =
    "bg-brightred hover:bg-darkred text-white p-2 rounded min-w-48 text-center text-xl tracking-wide";

  if (external) {
    return (
      <a href="/" target="_blank" rel="noreferrer">
        <div className={classes}>{label}</div>
      </a>
    );
  } else if (submit) {
    return (
      <button type="submit" className={classes}>
        {label}
      </button>
    );
  }
  return (
    <Link to={`/${link}`}>
      <div className={classes}>{label}</div>
    </Link>
  );
};

Button.propTypes = {
  external: PropTypes.bool,
  label: PropTypes.string.isRequired,
  submit: PropTypes.bool,
  link: PropTypes.string,
};

Button.defaultProps = {
  external: false,
  submit: false,
  label: "Learn More",
};

export default Button;

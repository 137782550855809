/* eslint-disable global-require */
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Button from "./button";
import headerData from "../../content/header-footer/header.json";
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ hideAngle }) => (
  <header className="bg-charcoal">
    <div className="container m-auto flex items-center justify-between p-5 flex-col sm:flex-row">
      <Link to="/">
        <StaticImage
          src="../images/IdealSeal_Logo_Hor_ColorDark.png"
          alt="idealseat logo"
          className="max-w-full sm:max-w-xs py-6 md:p-0"
        />
      </Link>
      {headerData.display_blog_link && (
        <Link
          to="/blog"
          className="sm:ml-auto text-white hover:text-brightred text-2xl py-2 px-10"
        >
          Resources
        </Link>
      )}
      <Button label={headerData.label} link={headerData.link.toLowerCase()} />
    </div>
    <div
      className={`header-bg bg-cover bg-bottom bg-no-repeat bg-charcoal h-16 ${
        hideAngle ? "hidden" : ""
      }`}
    />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  hideAngle: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

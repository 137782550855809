/* eslint-disable global-require */
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Button from "./button";
import footerData from "../../content/header-footer/footer.json";
import Image from './image';

import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const socialLinks = [
  { link: "https://www.facebook.com/IdealSeat/", icon: <FaFacebook /> },
  { link: "https://www.instagram.com/idealseat/", icon: <FaInstagram /> },
  { link: "https://www.linkedin.com/company/idealseat/", icon: <FaLinkedin /> },
];

const social = [];
socialLinks.map((item) => {
  social.push(
    <a
      key={item.link}
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      className="p-2 text-4xl text-brightred hover:text-white inline-block"
    >
      {item.icon}
    </a>
  );
});

const Footer = () => (
  <footer className="angle-subtle-tr-bg bg-charcoal bg-contain bg-top bg-no-repeat pt-32">
    <div className="container m-auto flex lg:flex-nowrap flex-wrap items-center justify-center p-5 flex-col sm:flex-row">
      <Link to="/" className="w-full lg:w-auto">
        <Image
          filename="IdealSeal_LogoTag_Hor_ColorDark.png"
          alt="idealseat logo"
          className="max-w-full sm:max-w-xs m-auto block"
        />
      </Link>
      <div className="text-white pt-12 lg:pt-0 lg:ml-auto flex-col px-12 self-start text-center sm:text-left lg:text-right w-full sm:w-auto">
        <Link
          to="/privacypolicy"
          className="block text-lg hover:text-brightred"
        >
          Privacy Policy
        </Link>
        <Link
          to="/termsandconditions"
          className="block text-lg hover:text-brightred"
        >
          Terms and Conditions
        </Link>
        <a
          href="http://bandwagonfanclub.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-lg hover:text-brightred"
        >
          Bandwagon
        </a>
        <a
          href="https://www.notion.so/IdealSeat-Wiki-d8e4c5c963d5442dbc584bf4ecffade2"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-lg hover:text-brightred"
        >
          Wiki
        </a>
      </div>
      <div className="flex flex-col pt-12 lg:pt-0">
        <Button label={footerData.label} link={footerData.link.toLowerCase()} />
        <div className="text-center sm:text-right py-3">{social}</div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "../styles/index.scss";

const Layout = ({ children, hideHeaderAngle, centered }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="min-h-screen flex flex-col">
      <Header
        siteTitle={data.site.siteMetadata.title}
        hideAngle={hideHeaderAngle}
      />
      <main
        className={`flex-grow ${
          centered ? "flex items-center justify-center" : ""
        }`}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeaderAngle: PropTypes.bool,
  centered: PropTypes.bool,
};

export default Layout;

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = (props) => {
  return <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render={(data) => {
        const effectiveFilename = props.filename.startsWith('/') ?
                props.filename.substring(1) : props.filename;

      const image = data.images.edges.find(
            n => n.node.relativePath.includes(effectiveFilename));

      if (!image) {
        return null;
      }

      return <GatsbyImage {...props} image={image.node.childImageSharp.gatsbyImageData} />;
    }}
  />
};

Image.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
